
/*::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

.about-container {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;

    width: 98%;
}

.about-section {
    margin: 0 12px;
    text-align: left;
}

.tools-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    text-align: center;
    align-items: end;
}

.tool-icon {
    width: 30%;
}

.tool-name {
    font-weight: 100;
    letter-spacing: 1px;
    color: #484848;
}

.section-label {
    font-size: 28px;
    font-weight: 700;
    color: #ff8674;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0;
}

.content-text {
    font-size: 20px;
    color: #021D44;
    font-weight: 100;
    letter-spacing: 1px;
}


@keyframes modalIn {
    0% {
        transform: translateX(-2000px) scale(0);
    }
    50% {
        transform: translateX(0) scale(.3);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes modalOut {
    0% {
        transform: translateY(0) scale(1);
    }
    50% {
        transform: scale(.3);
    }
    70% {
        transform: translateY(-1000px) scale(.3);
    }
    100% {
        transform: translateY(-1000px) scale(0);
    }
}


@media only Screen and (max-width: 900px) {
    .about-container {
        grid-template-columns: 1fr;
        width: 100%;
    }

    .tool-icon {
        width: 30%;
    }
}

@media only Screen and (max-width: 600px) {
    .section-label {
        font-size: 22px;
    }

    .content-text {
        font-size: 18px;
    }
}