/*.project-label {*/
/*    height: 100vh;*/
/*    text-align: right;*/
/*    display: grid;*/
/*    justify-content: end;*/
/*    align-content: center;*/
/*    background: #F7F6F5;*/
/*}*/

.project-label {
    height: 100vh;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    background: #F7F6F5;
}


.project-label > h1 {
    text-transform: uppercase;
    font-size: 10vw;
    color: #ea4352;
    letter-spacing: 20px;
    animation: inOnRight .5s ease forwards;
    margin: 0;
    padding: 0;
    display: grid;
}

.project-label > span {
    text-transform: uppercase;
    font-size: 10vw;
    color: #ea4352;
    letter-spacing: 20px;
    margin: 0;
    padding: 0;
    transition: all 1s ease;
    opacity: 0;
    align-self: center;
}

.project-label > span:nth-of-type(1){
    animation: load 1s ease forwards;
}

.project-label > span:nth-of-type(2){
    animation: load 1s ease forwards;
    animation-delay: .1s;
}

.project-label > span:nth-of-type(3){
    animation: load 1s ease forwards;
    animation-delay: .2s;
}

.project-label > span:nth-of-type(4){
    animation: load 1s ease forwards;
    animation-delay: .3s;
}

.project-label > span:nth-of-type(5){
    animation: load 1s ease forwards;
    animation-delay: .4s;
}

.project-label > span:nth-of-type(6){
    animation: load 1s ease forwards;
    animation-delay: .5s;
}

.project-label > span:nth-of-type(7){
    animation: load 1s ease forwards;
    animation-delay: .5s;
}

.project-label > span:nth-of-type(8){
    animation: load 1s ease forwards;
    animation-delay: .7s;
}

.projects-container {
    display: grid;
    margin: 0 auto 5rem;
    grid-gap: 150px;
    animation: inFromBottom .5s ease forwards;
}

.projects-container > h1 {
    text-transform: uppercase;
}

.projects {
    display: grid;
    height: auto;
}

.preview {
    height: 35vw;
    border-radius: 4px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 98%;
    margin: 0 auto;
}

.panel {
    width: 90%;
    margin: 0 auto;
    padding: 12px;
    height: auto;
    border-radius: 4px;
    position: relative;
    top: -6%;
    background: #f7f6f5;
    box-shadow: 0 -15px 15px -10px rgba(0, 0, 0, .4);
}

.project-name {
    color: #021D44;
    font-size: 70px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0;
    padding: 0;
}

.demo-button {
    width: 130px;
    height: auto;
    border: none;
    font-weight: 100;
    font-family: 'Teko', sans-serif;
    color: #ffffff;
    letter-spacing: 1px;
    font-size: 25px;
    text-transform: uppercase;
    background: #f6843e;
    padding: 10px;
    border-radius: 4px;
    margin: 20px 0;
    transition: all .3s ease;
}

.demo-button:hover, .github-button:hover {
    cursor: pointer;
    box-shadow: 1px 10px 9px -2px rgba(0,0,0,.4);
    transform: translateY(-5px) translateX(2px);

}

.github-button {
    width: 130px;
    height: auto;
    border: 1px solid #0000001f;
    font-weight: 100;
    font-family: 'Teko', sans-serif;
    color: #f63e54;
    letter-spacing: 1px;
    font-size: 25px;
    text-transform: uppercase;
    background: transparent;
    padding: 10px;
    border-radius: 4px;
    margin: 20px 0 auto 8px;
    transition: all .3s ease;
}



.fa-arrow-right {
    font-size: 18px;
    margin-left: 3px;
}


@keyframes inOnRight {
    from {
        transform: translateX(-1000px);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes inOnLeft {
    from {
        transform: translateX(-1000px);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes inFromBottom {
    from {
        transform: translateY(1000px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes projectAnimation {
    0% {
        left: -10px;
    }
    50% {
        left: 10px;
    }
    100% {
        left: -10px;
    }
}

@keyframes projectAnimationSmall {
    0% {
        left: -5px;
    }
    50% {
        left: 5px;
    }
    100% {
        left: -5px;
    }
}

@keyframes load {
    0% {
        opacity: 0;
        transform: translateY(-100px) rotateY(-360deg) scale(.4);
    }

    100% {
        opacity: 1;
        transform: translateY(0) rotateY(360deg) scale(1);
    }
}

@media only Screen and (max-width: 900px) {
    .projects-container {
        width: auto;
    }

    .preview {
        height: 28vh;
        border-radius: 0;
        width: 100vw;
    }

    .projects {
        display: grid;
        grid-template-columns: 1fr;
    }


    .project-label {
        align-content: center;
        justify-content: center;
    }

    .project-label > h1 {
        font-size: 15vw;
    }

    .each-project {
        padding: 20px;
    }

    .project-name {
        margin: 0 5px;
    }

    .demo-button {
        margin: 10px 5px;
    }
}
