.contact-button {
    font-size: 15px;
    background-color: transparent;
    border: none;
    transition: transform 1s ease;
}

.contact-button:hover {
    cursor: pointer;
}

.links {
    display: flex;
    justify-content: center;
    margin: 15px;
}

.fab {
    color: #021D44;
    font-size: 25px;
    transition: all 1s ease;
}

.fab:hover {
    transform: rotate(360deg) scale(1.5);
}

@media only Screen and (max-width: 900px) {
    .contact-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
    }

    .contact-button {
        font-size: 12px;
    }

    .links {
        margin: 0;
    }
}

@media only Screen and (max-width: 400px) {
    /*.contact-container {*/
    /*    width: fit-content;*/
    /*}*/
}