.App {
    height: 100vh;
    display: grid;
    grid-template-columns: 15% auto 10%;
    overflow: scroll;
    color: #F7F6F5;
}

.left {
    background: #F7F6F5;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40% auto;
    color: #f63e54;
    position: fixed;
    height: 100vh;
    width: 15%;
}

.name {
    display: grid;
    justify-content: center;
    align-content: center;
}

#logo {
    width: 12vw;
    /*transform: translateX(-1000px) rotate(-25deg);*/
    /*animation: inOnRight 1s ease forwards;*/
    transform: rotate(-25deg);
}


.contact-section {
    display: grid;
    justify-content: center;
    align-content: end;
}

.top-nav {
    display: none;
}

.left > section > h1 {
    font-size: 4vh;
    font-weight: 100;
    margin: 0;
    letter-spacing: 8px;
    text-transform: uppercase;
}

.content {
    background: #F7F6F5;
    position: absolute;
    left: 15%;
    overflow: scroll;
    width: 75%;
}

.right {
    background: #ea4353;
    position: fixed;
    width: 10%;
    right: 0;
    height: 100vh;
    transition: all 1s ease;
}


.menu-container {
    width: 100%;
    height: 60px;
}


.view {
    /*overflow: scroll;*/
    /*height: 100vh;*/
    transition: all .8s ease-out;
}

.page {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
}

.item-enter {
    opacity: 0;
}

.item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.item-exit {
    opacity: 1;
}

.item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}

.footer {
    display: none;
}

@keyframes openNav {
    0% {
        height: 0;
        opacity: 0;
        visibility: hidden;
        transform: scale(0) translateY(-150px);
    }
    100% {
        height: auto;
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(0);
    }
}

@keyframes collapseNav {
    0% {
        height: auto;
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(0);
    }
    100% {
        height: 0;
        opacity: 0;
        visibility: hidden;
        transform: scale(0) translateY(-150px);
    }
}


@media only Screen and (max-width: 900px) {
    .App {
        grid-template-columns: 1fr;
    }

    .left {
        position: fixed;
        height: 10%;
        width: 100vw;
        z-index: 1;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }

    .name {
        grid-template-rows: auto;
        justify-content: start;
        align-content: center;
        margin: 15px;
    }

    .left > section > h1 {
        font-size: 2vh;
        margin: 5px;
    }

    .content {
        position: absolute;
        top: 7%;
        left: 0;
        width: 100vw;
    }

    .contact-section {
        display: none;
    }

    .top-nav {
        display: grid;
        align-content: center;
        justify-content: end;
    }

    .right {
        display: none;
    }

    .footer {
        width: 100vw;
        height: 100px;
        background: #ea4353;
        display: grid;
    }
}