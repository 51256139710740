.nav-container {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 100vh;
}

#tab {
    position: fixed;
    top: 18px;
    color: white;
    background: #eb4446;
    width: 45px;
    border-radius: 0 5px 5px 0;
    padding: 4px;
    font-size: 23px;
    transform: translateX(0);
    z-index: 200;
    display: grid;
    justify-content: center;
}

#tab:hover {
    cursor: pointer;
}

.navClosed {
    animation: navOut 1s ease forwards;
}


.logo {
    pointer-events: none;
    margin: 15px;
    font-size: 40px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transform: rotate(-90deg) translate(-55px, -70px);
}

.close-menu-section {
    height: 12px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    background: #1b1a1a;
    border: 1px solid #1b1a1a;
    border-radius: 5px;
    padding: 8px;
}

.fa-chevron-up {
    position: absolute;
    left: 0;
    top: 10px;
    border-radius: 5px 5px 0 0;
    width: 100%;
    background: #1b1a1a;
    font-size: 14px;
}

.fa-chevron-up:hover {
    cursor: pointer;
}

.isActive {
    color: #cd5e5c;
}

.menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    font-size: 20px;
    letter-spacing: 3px;
    font-weight: 900;
    text-align: center;
}

.menu-list-item {
    margin: 15px;
    text-decoration: none;
    color: #F7F6F5;
    transition: all .3s ease;
    font-size: 25px;
}

.menu-list-item:hover {
    color: #021D44;
}



@keyframes expand {
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: auto;
        opacity: 1;
    }
}

@keyframes shrink {
    from {
        height: auto;
        opacity: 1;
    }
    to {
        height: 0;
        opacity: 0;
    }
}

@keyframes navOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-430px);
    }
}

@keyframes navIn {
    from {
        transform: translateX(-430px);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes tabShiftRight {
    from {
        transform: translateX(0);
        border-radius:  0 5px 5px 0;
    }
    to {
        transform: translateX(181px) rotateY(-360deg);
        border-radius: 5px 0 0 5px;
    }
}

@keyframes tabShiftLeft {
    from {
        transform: translateX(181px);
        border-radius: 5px 0 0 5px;
    }
    to {
        transform: translateX(0) rotateY(360deg);
        border-radius:  0 5px 5px 0;
    }
}

@media only Screen and (max-width: 900px) {
    .nav-container {
        height: fit-content;
    }

    .menu-list {
        grid-template-columns: 1fr 1fr;
    }

    .menu-list-item {
        margin: 15px;
        text-decoration: none;
        color: #021D44;
        font-size: 18px;
    }

    .menu-list-item:hover {
        color: #f63e54;
    }
}
