html, body {
  margin: 0;
  /*font-family: 'Teko', sans-serif;*/
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}




/* #D83F87 - pink*/
/* #2A1B3D - purple*/
/* #44318D - medium purple*/
/* #E98074 - light purple*/
/* #A4B3B6 - light gray*/



/* #FC4747 - RED*/
/* #BBEFEF - light blue*/
/* #ff8674 - pink*/
/* #FFD162 - yellow*/
/* #A4B3B6 - light gray*/
/* #173e40 - text*/




/*
#021D44  -  DARK BLUE
#f63e54  -  RED
#F7F6F5  -  WHITE
*/